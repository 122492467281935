import React from "react";

const Loader = () => {



  return (
    <div className="flex items-center justify-center w-full h-[calc(100vh-100px)]">
      <div className="flex justify-center items-center space-x-1 text-sm text-gray-700">
        <div className="flex items-center justify-center ">
          <div className="w-40 h-40 border-t-4 border-b-4 border-black rounded-full animate-spin"></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
