import React, { useEffect, useState } from "react";
import Layout from "../../components/Layout";
import { useQuery, gql } from "@apollo/client";
import { logout } from "../../components/Auth";
import { getUser } from "../../components/Auth";
import { Link } from "gatsby";
import { formatPrice } from "../../utils/formatPrice";
import LoginError from "../../components/LoginError";
import Loader from "../../components/Loader";

const GET_CUSTOMER = gql`
  query ($handle: String!) {
    customer(customerAccessToken: $handle) {
      id
      firstName
      lastName
      acceptsMarketing
      email
      phone
      orders(first: 100, reverse: true) {
        edges {
          node {
            id
            name
            processedAt
            currentTotalPrice {
              amount
              currencyCode
            }
            orderNumber
            email
            phone
            shippingAddress {
              address1
              address2
              city
              company
              country
              countryCodeV2
              firstName
              lastName
            }
            fulfillmentStatus
            lineItems(first: 100, reverse: false) {
              edges {
                node {
                  currentQuantity
                  title
                  customAttributes {
                    key
                    value
                  }
                  discountedTotalPrice {
                    amount
                    currencyCode
                  }
                  originalTotalPrice {
                    amount
                    currencyCode
                  }
                  variant {
                    image {
                      url
                      altText
                      originalSrc
                      width
                      height
                    }
                    title
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
const Orders = () => {
  const [firstName, setName] = useState("");
  const [lastName, setLastName] = useState("");

  const token = getUser().token;

  const { error, data, loading } = useQuery(GET_CUSTOMER, {
    variables: { handle: token },
  });

  useEffect(() => {
    if (data) {
      setName(data?.customer?.firstName);
      setLastName(data?.customer?.lastName);
    }
  }, [data]);

  if (error) return <LoginError />;

  return (
    <Layout>
      {loading ? (
        <Loader />
      ) : (
        <div className="pt-12 lg:flex">
          <div className="flex flex-col w-full px-4 py-8 overflow-y-auto border-b lg:border-r lg:h-screen lg:w-64">
            <div className="flex flex-col justify-between mt-6">
              <aside>
                <p className="font_grey_medium_3">Hello,</p>
                <p className="font_xl font_semibold text-uppercase mb-4">
                  {firstName} {lastName}
                </p>

                <ul>
                  <li>
                    <Link
                      className="flex items-center px-4 py-2 mt-5 text-gray-600 rounded-md hover:bg-gray-200"
                      to="/account"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"
                        />
                      </svg>

                      <span className="mx-4 font-medium">Dashboard</span>
                    </Link>
                  </li>

                  <li>
                    <Link
                      className="flex items-center px-4 py-2 mt-5 text-gray-700 bg-gray-100 rounded-md"
                      to="/my-orders"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>

                      <span className="mx-4 font-medium">My orders</span>
                    </Link>
                  </li>

  
                  <li>
                    <Link
                      to="/logout"
                      onClick={(event) => {
                        event.preventDefault();
                        logout(() => window.location.assign("/account"));
                      }}
                      className="flex items-center px-4 py-2 mt-5 text-gray-600 rounded-md hover:bg-gray-200"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-6 h-6"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>

                      <span className="mx-4 font-medium">Logout</span>
                    </Link>
                  </li>
                </ul>
              </aside>
            </div>
          </div>
          <div className="w-full h-full overflow-y-auto">
            <h1 className="lg:text-5xl  font-bold leading-tight text-3xl px-4 my-8">
              Orders
            </h1>

            <div className="px-4 overflow-x-auto relative shadow-md sm:rounded-lg">
              <table className="px-4 w-full text-sm text-left text-gray-500">
                <thead className="text-xs text-gray-700 uppercase bg-gray-50">
                  <tr>
                    <th scope="col" className="py-3 px-6">
                      Order
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Date
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Status
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Total
                    </th>
                    <th scope="col" className="py-3 px-6">
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.customer?.orders?.edges.map((order) => {
                    return (
                      <tr className="bg-white border-b" key={order.node.id}>
                        <th
                          scope="row"
                          className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap"
                        >
                          {order.node.orderNumber}
                        </th>
                        <td className="py-4 px-6">
                          {new Date(
                            order.node.processedAt
                          ).toLocaleDateString()}
                        </td>
                        <td className="py-4 px-6">
                          {order.node.fulfillmentStatus}
                        </td>
                        <td className="py-4 px-6">
                          {formatPrice(
                            order.node.currentTotalPrice.currencyCode,
                            order.node.currentTotalPrice.amount
                          )}
                        </td>
                        <td className="py-4 px-6">
                          <Link
                            to={`/my-orders/order/${order.node.name}`}
                            className="font-medium text-blue-600 hover:underline"
                      
                          >
                            View order
                          </Link>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </Layout>
  );
};

export default Orders;
